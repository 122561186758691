//.fb-profile img.fb-image-lg {
//
//}
//
//.fb-image-profile {
//}

//@media (max-width: 768px) {
//
//  .fb-profile-text > h1 {
//    font-weight: 700;
//    font-size: 16px;
//  }
//
//  .fb-image-profile {
//    margin: -45px 10px 0px 25px;
//    z-index: 9;
//    width: 20%;
//  }
//}

.profile {
  .cover {
    position: relative;
    width: 100%;
    margin: 2em 0 0;
    z-index: 1;
    /*height: calc(width * .25);*/
    .buttons {
      opacity: 0;
      position: absolute;
      right: 1em;
      top: 1em;
      transition: opacity .1s;
    }

    &:hover {
      .buttons {
        opacity: 1;
      }
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 3px;
      background: #c71717;
      box-shadow: 0 0 1px #191;
      z-index: 10;
    }

    > .editor {
      position: absolute;
      left: 0;
      top: 0;
    }

    .avatar {
      position: absolute;
      z-index: 9;
      width: 15%;
      height: 100%;
      left: 5%;
      top: 0;
      /*height: calc(width * .25);*/
      img, svg {
        position: absolute;
        left: 0;
        bottom: -15%;
        width: 100%;
      }

      button {
        visibility: hidden;
        position: absolute;
        left: 0;
        bottom: -15%;
        width: calc(100% - 10px);
        margin: 5px;
        height: 1px;
        transition: height .1s;
        padding: 0;
        border: 0;
        background: none;
        color: #fff;
      }

      &:hover {
        button {
          visibility: visible;
          height: 40%;
          background: rgba(0, 0, 0, .4);
        }
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      left: 22%;
      color: #fff;
      text-rendering: optimizelegibility;
      text-shadow: 0 0 3px rgba(0, 0, 0, .8);
    }
  }

  .profile-nav {
    .navbar {
      padding-left: 22%;

      .nav-item {
        border-left: 1px solid #ccc;
        padding: 0 1em;

        &:last-child {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}

