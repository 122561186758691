@-webkit-keyframes sentimentorLoading {
  from {
    transform: rotate(0deg) scale(.9);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideup {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slidedown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.sentimentor {
  button {
    border: 0;
    box-shadow: none;
    outline: 0;
    border-radius: 50%;

    width: 30px;
    height: 30px;
    font-size: 18px;
    margin: 0 .25em;
    transition: all .5s;
    text-align: center;

    > span {
      display: block;
      height: 30px;
      width: 18px;
      line-height: 30px;
      transform: rotate(90deg) scale(.9);
      transition: all .5s;
      white-space: nowrap;
    }

    &:hover > span {
      transform: rotate(450deg) scale(1.1);
    }

    &:active {
      > span {
        transform: rotate(450deg) scale(.9);
      }

      box-shadow: none;
    }

    &.positive {
      color: #769653;
    }

    &.negative {
      color: #D95043;

    }

    &.notr {
      color: dimgray;


    }


    &.remove {
      color: white;
      background: darkgray;
      transform: rotate(0deg);
      font-size: 12px;

      &:hover {
        background: #D95043;
        transform: none;
      }
    }
  }

  &.positive > .positive {
    color: #fff;
    background: #769653;
  }

  &.positiveLoader > .positive {
    border: 1px solid #769653;
    animation: sentimentorLoading .5s ease-in-out infinite;
  }

  &.notr > .notr {
    color: #fff;
    background: dimgrey;
  }

  &.notrLoader > .notr {
    border: 1px solid dimgray;
    animation: sentimentorLoading .5s ease-in-out infinite;
  }

  &.negative > .negative {
    color: #fff;
    background: #D95043;
  }

  &.negativeLoader > .negative {
    border: 1px solid #D95043;
    animation: sentimentorLoading .5s ease-in-out infinite;
  }
}

.affix {
  transition: all .5s;
  white-space: nowrap;
}

.set-info {
  font-size: small;
  background: #fff;
  padding: .5em;
  margin: 0 -.5em;

  span {
    padding: 0 .5em;

    &.up {
      animation-duration: 1s;
      animation-name: slideup;
    }

    &.down {
      animation-duration: 1s;
      animation-name: slidedown;
    }
  }
}

.positive {
  color: #769653
}

.negative {
  color: #D95043;
}

.neutral {
  color: dimgray;
}

i.positive, i.negative, i.neutral {
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 18px;
  font-size: 11px;
  text-align: center;
  transform: rotate(90deg);
  transition: all .5s;
  white-space: nowrap;
  font-style: normal;
  border-radius: 50%;
  color: #fff;
  vertical-align: middle;
  margin: 0 1px;
}

i.positive {
  background: #769653;
}

i.negative {
  background: #D95043;
}

i.neutral {
  background: dimgray;
}


.loader {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
}

.loader.background {
  background: radial-gradient(#fff, #c8c8c8);
}
