/*.image-input {*/
/*height: 0;*/
/*overflow: hidden;*/
/*}*/

.CoverImage {
  position: relative;
  margin: 0 auto 80px;
  width: 100%;
  min-height: 200px;
  z-index: 98;
  background: #333;
  cursor: pointer;

  .loader {
    display: none;
  }

  .uploading {
    > .loader {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      top: 0;
      background: rgba(255, 255, 255, .4);
    }

    .fa {
      display: flex;
      margin: 0 auto;
      color: #0F0F0F;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ProfileImage {
  position: absolute;
  margin-left: -80px;
  margin-bottom: -80px;
  left: 50%;
  bottom: 0;
  width: 160px;
  height: 160px;
  z-index: 99;
  cursor: pointer;
  background: #333;
  border: 2px solid #fff;

  .loader {
    display: none;
  }

  .uploading {
    > .loader {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      top: 0;
      background: rgba(255, 255, 255, .4);
    }

    .fa {
      display: flex;
      margin: 0 auto;
      color: #0F0F0F;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: solid 5px #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }
}
