.image-input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .input-container {
    height: 0;
    overflow: hidden;
  }

  img {
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }

  i {
    cursor: pointer;
  }
}

.image-input-container {
  height: 0;
  overflow: hidden;
}

.react-datepicker-wrapper {
  display: block !important;
}
